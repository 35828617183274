<template>
  <v-dialog :value="isOpen" max-width="700px" @click:outside="close" @keydown.esc="close">
    <v-card>
      <v-card-title>{{ translations.location }} {{ name }}</v-card-title>
      <v-card-text>
        <l-map ref="map" :zoom="zoom" :center="center" style="width: 100%; height: 400px">
          <l-tile-layer :url="url"/>
          <l-marker ref="markerIn" v-if="latLngIn" :lat-lng="latLngIn">
            <l-popup>
              <a href="#" @click="openMap(latLngIn)">{{ translations.in }}</a>
            </l-popup>
          </l-marker>
          <l-marker ref="markerOut" v-if="latLngOut" :lat-lng="latLngOut">
            <l-popup @click="openMap(latLngOut)">
              <a href="#" @click="openMap(latLngOut)">{{ translations.out }}</a>
            </l-popup>
          </l-marker>
        </l-map>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { translations } from '@/utils/common'
import { latLng } from 'leaflet'
import { LMap, LMarker, LPopup, LTileLayer } from 'vue2-leaflet'

export default {
  name: 'ClockInMap',

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },

  props: {
    isOpen: {
      type: Boolean
    },

    name: {
      type: String
    },

    locationIn: {
      type: String
    },

    locationOut: {
      type: String
    },

    clickOn: {
      type: String
    }
  },

  data() {
    return {
      zoom: 16,
      center: null,
      latLngIn: null,
      latLngOut: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }
  },

  computed: {
    translations: () => translations
  },

  watch: {
    isOpen() {
      if (this.$refs.markerIn) {
        this.$refs.markerIn.mapObject.closePopup()
      }
      if (this.$refs.markerOut) {
        this.$refs.markerOut.mapObject.closePopup()
      }

      const self = this
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
      setTimeout(() => {
        if (this.$refs.markerIn) {
          self.$refs.markerIn.mapObject._icon.classList.add('marker-in')
        }
        if (this.$refs.markerOut) {
          self.$refs.markerOut.mapObject._icon.classList.add('marker-out')
        }

        if (self.clickOn === 'IN') {
          if (this.$refs.markerIn) {
            self.$refs.markerIn.mapObject.openPopup()
          }
        } else if (this.$refs.markerOut) {
          self.$refs.markerOut.mapObject.openPopup()
        }
      }, 250)
    },

    locationIn() {
      this.latLngIn = null
      this.center = null
      if (this.locationIn) {
        const l = JSON.parse(this.locationIn)
        this.latLngIn = latLng(l.latitude, l.longitude)
        this.center = this.latLngIn
      }
    },

    locationOut() {
      this.latLngOut = null
      if (this.locationOut) {
        const l = JSON.parse(this.locationOut)
        this.latLngOut = latLng(l.latitude, l.longitude)

        if (!this.locationIn) {
          this.center = this.latLngOut
        } else {
          const self = this
          setTimeout(() => self.$refs.map.mapObject.fitBounds([self.latLngIn, self.latLngOut], { padding: [40, 40] }), 500)
        }
      }
    }
  },

  methods: {
    openMap(latLng) {
      // if we're on iOS, open in Apple Maps, otherwise use Google
      let prefix = (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPad') != -1 || navigator.platform.indexOf('iPod') != -1) ? 'maps' : 'https'
      window.open(prefix + '://maps.google.com/maps?daddr=' + latLng.lat + ',' + latLng.lng + '&amp;ll=')
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>


<style>
.marker-in {
  filter: hue-rotate(240deg);
}

.marker-out {
  filter: hue-rotate(140deg);
}

.leaflet-bottom {
  display: none;
}
</style>
