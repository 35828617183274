<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
        <!-- employee filter -->
        <v-autocomplete :items="employeeList" item-text="name" item-value="id" :placeholder="translations.filterForEmployee"
                        :search-input.sync="employeeSearch" :loading="$apollo.queries.employees.loading"
                        @change="val => (filters.employeeId = val)" @click:clear="filters.employeeId = null"
                        clearable dense hide-details autocomplete="off">
          <template v-slot:append-item>
            <div style="background: white;" class="d-flex justify-center" v-intersect="onIntersect" v-if="hasNextEmployees && !$apollo.queries.employees.loading">
              <v-progress-circular indeterminate color="main"></v-progress-circular>
            </div>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              {{ translations.noEmployeesFoundWThatName }}
            </v-list-item>
          </template>
        </v-autocomplete>

        <!-- project filter -->
        <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              clearable dense hide-details autocomplete="off"
              @input="value => projectFilterHandler({ value, property: 'availableProjectOptions', resetProjectId: true })"
              @click:clear="projectFilterHandler({ value: '', property: 'availableProjectOptions', resetProjectId: true }), (projectIdThrowAway = ''), (filters.projectId = null)"
              append-icon="mdi-menu-down"
              :placeholder="projectIdThrowAway ? projectIdThrowAway : translations.filterForProject"
              :value="projectIdThrowAway"/>
          </template>

          <div style="max-height: 300px" class="scroll-bar">
            <v-list dense>
              <v-list-item v-for="(project, index) in availableProjectOptions.projects" :key="`project-${project.id}_${index}`" @click="selectProjectHandler(project)">
                <v-list-item-content>
                  <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                  <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!availableProjectOptions.loading && availableProjectOptions.hasNext">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onProjectIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>

        <!-- Confirmed ClockIns filter -->
        <v-select :items="confirmedFilterItems" v-model="filters.confirmed"></v-select>

        <!-- Driver/Operator filter  -->
        <v-select v-model="filters.teamTypes" :items="teamTypeFilterItems" :label="translations.filterForTeam" chips multiple class="mr-2"></v-select>
      </div>

      <!-- page header: title -->
      <div class="all-center">
        <date-header @on-date-change="val => changeDate(val.date)" :value="date"/>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <!-- confirm clock-in -->
        <div class="all-center" style="width: 20%">
          <v-btn color="main" class="white--text" :title="translations.confirmTodaysTimes"
                 v-if="!allClockInsAreConfirmed" @click="checkCompletedClockInsForConfirm">
            <v-icon>mdi-playlist-edit</v-icon>
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-alert v-else text type="success" class="all-clockins-confirmed" :title="translations.allClockInsConfirmed"/>
        </div>

        <!-- export report toolbar -->
        <div class="all-center" style="width: 60%">
          <export-report-toolbar :exportReports="exportReports"></export-report-toolbar>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">

      <!-- table header -->
      <section class="grid-row pa-3 sticky-header" :style="[{ gridTemplateColumns: `200px 50px ${middleColumnWidth}` }]">
        <div class="table-header justify-start d-flex pl-6">
          <strong>{{ translations.employees }}</strong>
        </div>
        <div></div>
        <!-- Middle scrollable container -->
        <div class="grid-mid-row horizontal-scroll-bar" :style="{ maxWidth: middleColumnWidth, gridTemplateColumns: middleContainerTemplateColumns }" @scroll="syncScroll('grid-mid-row')">
          <div class="table-header">
            <strong>{{ translations.project }} / {{ translations.category }}</strong>
          </div>
          <div class="table-header">
            <strong>{{ translations.part }}</strong>
          </div>
          <div class="table-header">
            <strong>{{ translations.subpart }}</strong>
          </div>
          <div class="table-header">
            <strong>{{ translations.allowance }}</strong>
          </div>
          <div class="table-header">
            <strong>{{ translations.in }} ({{ translations.time }})</strong>
          </div>
          <div class="table-header">
            <strong>{{ translations.out }} ({{ translations.time }})</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.workHours }}</strong>
          </div>

          <div v-if="this.possibleQuestions.includes(4)" class="justify-center d-flex table-header">
            <strong>{{ translations.lunchBreak }}</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.layoff }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(1)" class="justify-center d-flex table-header">
            <strong>{{ translations.van }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(2)" class="justify-center d-flex table-header">
            <strong>{{ translations.refuel }}</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.maintenance }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(3)" class="justify-center d-flex table-header">
            <strong>{{ translations.milling }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(20)" class="justify-center d-flex table-header">
            <strong>{{ translations.preparation }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(5)" class="justify-center d-flex table-header">
            <strong>{{ translations.dpiVerification }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(6)" class="justify-center d-flex table-header">
            <strong>{{ translations.materialUsed }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(7)" class="justify-center d-flex table-header">
            <strong>{{ translations.asphaltTemperature }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(8)" class="justify-center d-flex table-header">
            <strong>{{ translations.environmentTemperature }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(9)" class="justify-center d-flex table-header">
            <strong>{{ translations.manHolesVerification }}</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.vehicleCode }}</strong>
          </div>
          <div v-if="this.possibleQuestions.includes(11)" class="justify-center d-flex table-header">
            <strong>{{ translations.security }}</strong>
          </div>
          <div class="justify-center d-flex table-header">
            <strong>{{ translations.notes }}</strong>
          </div>
          <div class="justify-center d-flex table-header pb-1">
            <strong>{{ translations.referenceTurn }}</strong>
          </div>
        </div>
      </section>

      <div class="page-table-custom">
        <!-- table result - loading -->
        <template v-if="apolloLoading > 0">
          <div class="apolloLoading">
            <socket></socket>
          </div>
        </template>

        <!-- table result - data -->
        <template v-else>
          <VirtualReportRow
            v-for="(clockInData, index) in clockInTableData"
            :key="clockInData.name"
            :index="index"
            :source="clockInData"
            :activeMenuList="activeMenuList"
            :categoryProjects="[]"
            :confirmed="clockInData.confirmed"
            :possibleQuestions="possibleQuestions"
            :middleColumnWidth="middleColumnWidth"
            :middleContainerTemplateColumns="middleContainerTemplateColumns"
            :materials="materials"
            :rowDialogProjectOptions="rowDialogProjectOptions"
            :rowProjectsOption="rowProjectsOption"
            :rowCategoryOptions="rowCategoryOptions"
            :rowDialogCategoryOptions="rowDialogCategoryOptions"
            @set-active-menu-list="value => (activeMenuList = value)"
            @new-clockin-pair-saved="(employeeName, clockInPair) => saveNewClockInPair(employeeName, clockInPair)"
            @clockin-pair-updated="(employeeName, clockInPair) => editClockInPair(employeeName, clockInPair)"
            @clockin-pair-deleted="(employeeName, clockInPair) => deleteClockInPair(employeeName, clockInPair)"
            @fetch-more-projects="
            value => {
              fetchProjects(value);
            }
          "
            @fetch-more-categories="
            value => {
              fetchCategories(value);
            }
          "
            @set-project-list-filter="projectFilterHandler"
            @set-project-category-list-filter="projectCategoryFilterHandler"
          ></VirtualReportRow>
        </template>
      </div>
    </v-card>

    <!-- Confirm Warning Dialog -->
    <v-dialog v-if="confirmWarningDialog" v-model="confirmWarningDialog" max-width="430px">
      <v-card>
        <v-card-title>
          <v-row class="ma-0" justify="center">
            <h4>{{ translations.warningMissingTimeStamp }}</h4>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row class="ma-0" justify="center">
            <v-col cols="6">
              <pending-svg></pending-svg>
            </v-col>
          </v-row>

          <strong>{{ translations.employeesMissingClockout }}</strong>
          <v-row class="ma-0">
            <ul>
              <li v-for="employee in incompleteClockInEmployees" :key="`incomplete-${employee.name}`" style="cursor: pointer" class="my-1">
                <v-btn @click="scrollEmployeeIndex(employee)" text small>
                  {{ employee.name }}
                </v-btn>
              </li>
            </ul>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="confirmWarningDialog = false" small text>{{ translations.close }}</v-btn>

          <v-btn @click="confirmTodaysClockIns" :loading="confirmLoading" small color="main" dark depressed>{{ translations.confirmAsIs }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmAllClockInDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ translations.saveAllClockIns }}</v-card-title>
        <v-card-text>
          <p style="font-size: 15px;">{{ translations.areYouSureYouWantToConfirmTimes }}</p>

          <div class="d-flex justify-end">
            <v-btn @click="confirmAllClockInDialog = false" small text class="mr-2">{{ translations.cancel }}</v-btn>
            <v-btn @click="confirmTodaysClockIns" small color="main" depressed :loading="confirmLoading" dark>
              {{ translations.confirm }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { uniqBy } from "lodash";
import debounce from "lodash/debounce";
import { mdiCalendar, mdiPlus, mdiChevronDown, mdiAlert } from "@mdi/js";
import { translations } from "@/utils/common";
import clockInQuery from "@/graphql/ClockIns.gql";
import employeesQuery from "@/graphql/Employees.gql";
import projectsQuery from "@/graphql/Projects.gql";
import categoryProjectsQuery from "@/graphql/CategoryProjects.gql";
import activeProjectsAndPartsQuery from "@/graphql/ActiveProjectAndParts.gql";
import confirmClockInsForOneDateMutation from "@/graphql/ConfirmClockInsForOneDate.gql";
import ExportReportToolbar from "@/components/ExportReport/ExportReportToolbar.vue";
import VirtualReportRow from "@/components/VirtualReportRow";
import DateHeader from "@/components/DateHeader.vue";
import PendingSvg from "@/assets/PendingSvg.vue";
import "vue2-timepicker/dist/VueTimepicker.css";
import { Socket } from "vue-loading-spinner";
import { getOperatorQuestionsArray, getOperatorForemanQuestionsArray, getDriverQuestionsArray, getTeamTypes } from "../utils/api/config.js";
import { getExportReports, getMaterials } from "@/utils/api/config";

export default {
  name: "Reports",

  components: {
    DateHeader,
    PendingSvg,
    VirtualReportRow,
    Socket,
    ExportReportToolbar
  },

  apollo: {
    // save for now
    // projects: {
    //   query: projectsQuery,
    //   variables() {
    //     return {
    //       filter: this.projectsFilter,
    //       fetchNonCategory: true,
    //       fetchCategory: false,
    //       activeStatus: true,
    //       pageSize: this.pageSize,
    //       page: this.projectsPage
    //     };
    //   },
    //   fetchPolicy: "network-only",
    //   result({ data }) {
    //     const { content, hasNext } = data.projects;
    //     this.availableProjects.push(...content);
    //     this.hasNextProjects = hasNext;
    //   }
    // },

    //save for now
    // categoryProjects: {
    //   query: categoryProjectsQuery,
    //   variables() {
    //     return {
    //       filter: this.categoryProjectsFilter,
    //       fetchNonCategory: false,
    //       fetchCategory: true,
    //       activeStatus: true,
    //       pageSize: this.pageSize,
    //       page: this.projectCategoriesPage
    //     };
    //   },
    //   fetchPolicy: "network-only",
    //   result({ data }) {
    //     const { content, hasNext } = data.categoryProjects;
    //     this.availableCategoryProjects.push(...content);
    //     this.hasNextCategories = hasNext;
    //   }
    // },

    activeProjectAndParts: {
      query: activeProjectsAndPartsQuery,
      variables() {
        return {
          dateStr: this.formatDate(this.date)
        };
      }
    },

    clockIns: {
      query: clockInQuery,
      loadingKey: "apolloLoading",
      notifyOnNetworkStatusChange: true,
      variables() {
        return {
          dateStr: this.formatDate(this.date),
          employeeId: null,
          teamTypes: null,
          confirmed: null,
          projectId: null,
          partId: null
        };
      },
      result(result) {
        if (result.data) {
          this.clockInTableData = result.data.clockIns.map(({ employee, clockIns, suggestedProjectParts, workHours }) => ({
            id: employee.id,
            name: employee.name,
            driver: employee.driver,
            startDate: this.formatDate(employee.employmentStartDateStr),
            endDate: this.formatDate(employee.employmentEndDateStr),
            date: this.date,
            clockIns: clockIns.length === 0 ? [] : clockIns?.map(a => ({
              id: a.clockIn?.id,
              clockIn: {
                ...a?.clockIn,
                part: a?.clockIn?.part || a?.clockOut?.part,
                updatedTimestamp: a?.clockIn?.updatedTimestampStr ? moment.utc(a?.clockIn?.updatedTimestampStr).format("HH:mm") : "",
                timeData: {
                  HH: a?.clockIn?.updatedTimestampStr ? moment.utc(a?.clockIn?.updatedTimestampStr).format("HH") : "",
                  mm: a?.clockIn?.updatedTimestampStr ? moment.utc(a?.clockIn?.updatedTimestampStr).format("mm") : ""
                }
              },
              clockOut: {
                ...a.clockOut,
                part: a?.clockOut?.part,
                updatedTimestamp: a?.clockOut?.updatedTimestampStr ? moment.utc(a?.clockOut?.updatedTimestampStr).format("HH:mm") : "",
                timeData: {
                  HH: a?.clockOut?.updatedTimestampStr ? moment.utc(a?.clockOut?.updatedTimestampStr).format("HH") : "",
                  mm: a?.clockIn?.updatedTimestampStr ? moment.utc(a?.clockOut?.updatedTimestampStr).format("mm") : ""
                }
              },
              edit: false,
              loading: false,
              search: a?.clockIn?.project?.keyCode || "",
              openMenu: false,
              layoff: a?.clockIn?.layoff || a?.clockOut?.layoff ? true : false,
              subpart: a?.clockIn?.subpart || a?.clockOut?.subpart,
              milling: a?.clockIn?.milling || a?.clockOut?.milling ? true : false,
              millingHours: a?.clockOut?.millingHours,
              notes: a?.clockOut?.notes,
              maintenance: a?.clockIn?.maintenance || a?.clockOut?.maintenance || a?.clockIn?.clockInItems?.length > 0 ? true : false,
              van: a?.clockOut?.van ? true : false,
              vanCode: a?.clockOut?.vanCode || "",
              vehicleCode: a?.clockIn?.vehicleCode || "",
              driver: a?.clockIn?.driver,
              lunchTime: a?.clockOut?.lunchTime >= 0 ? a.clockOut.lunchTime : 0,
              confirmed: a?.clockIn?.confirmed || a?.clockOut?.confirmed ? true : false,
              nextDayClockOut: this.formatDate(a?.clockOut?.updatedTimestampStr) > this.formatDate(a?.clockIn?.updatedTimestampStr),
              referenceDate: a?.clockIn?.referenceDateStr ? this.formatDate(a?.clockIn?.referenceDateStr) : null,
              dpiVerification: a?.clockIn?.dpiVerification || a?.clockOut?.dpiVerification ? true : false,
              manHolesVerification: a?.clockIn?.manHolesVerification || a?.clockOut?.manHolesVerification ? true : false,
              materialUsed: a?.clockIn?.materialUsed ? a?.clockIn?.materialUsed : a?.clockOut?.materialUsed,
              asphaltTemperature: a?.clockIn?.asphaltTemperature || a?.clockOut?.asphaltTemperature,
              environmentTemperature: a?.clockIn?.environmentTemperature || a?.clockOut?.environmentTemperature,
              didSecurityReport: a?.clockOut?.securityType ? true : false,
              securityType: a?.clockOut?.securityType ? JSON.parse(a.clockOut.securityType) : [],
              securityNote: a?.clockOut?.securityNote ? a.clockOut.securityNote : "",
              preparation: a?.clockIn?.preparation || a?.clockOut?.preparation ? true : false,
              preparationHours: a?.clockOut?.preparationHours
            })),
            projects: uniqBy(this.activeProjectAndParts, "project.id"),
            suggestedProjectParts: uniqBy(suggestedProjectParts, "project.id"),
            workHours: workHours,
            confirmed: clockIns.length > 0 && clockIns.every(ci => ci.clockIn.confirmed)
          }));

          this.setConfirmedStatus(this.clockInTableData);
          this.checkConfirmedClockIns();
        }
      },
      fetchPolicy: "no-cache"
    },

    employees: {
      query: employeesQuery,
      variables() {
        return {
          page: 1,
          pageSize: this.employeeQueryVars.pageSize,
          sort: "name",
          order: "asc",
          name: this.employeeSearch
        };
      }
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        // This function will be called when any property of `filters` change
        const clockInsVariables = {
          dateStr: this.date,
          projectId: newFilters.projectId,
          employeeId: newFilters.employeeId,
          confirmed: newFilters.confirmed,
          teamTypes: newFilters.teamTypes.length === 0 ? null : newFilters.teamTypes
        };

        this.$apollo.queries.clockIns.refetch(clockInsVariables);
      }
    },

    employees() {
      this.employeeList = this.employees.content;
      this.hasNextEmployees = this.employees.hasNext;
    },

    employeeSearch() {
      this.employeeQueryVars.page = 1;
    }
  },

  created() {
    if (this.$route.query.date) {
      this.date = this.$route.query.date;
    } else {
      this.date = this.getSubtractedDate(1, "days").substr(0, 10);
    }

    // using set to remove duplicate numeric values from the array
    const operatorQuestions = getOperatorQuestionsArray();
    const operatorForemanQuestions = getOperatorForemanQuestionsArray();
    const driverQuestions = getDriverQuestionsArray();
    const questions = operatorQuestions.concat(operatorForemanQuestions, driverQuestions);
    this.possibleQuestions = [...new Set(questions)];

    this.materials = getMaterials();
    this.fetchProjectOptions();
    this.fetchCategoryProjectOptions();
  },

  data: () => ({
    apolloLoading: 0,
    date: null,
    projectId: null,
    clockIns: [],
    employeeSearch: null,
    selectedLocation: {},
    clockInTableData: [],
    employeeList: [],
    activeProjectsAndParts: [],
    parentMenu: false,
    hasNextEmployees: false,
    projectIdThrowAway: "",
    employeeQueryVars: {
      page: 1,
      pageSize: 10,
      sort: "name",
      order: "asc"
    },
    confirmLoading: false,
    confirmWarningDialog: false,
    incompleteClockInEmployees: [],
    allClockInsAreConfirmed: false,
    confirmAllClockInDialog: false,
    filters: {
      dateStr: null,
      employeeId: null,
      projectId: null,
      confirmed: null,
      teamTypes: []
    },
    possibleQuestions: [],
    pageSize: 10,
    activeMenuList: "projects",
    materials: [],
    availableProjectOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      projectFilter: "",
      loading: false
    },
    rowDialogProjectOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      projectFilter: "",
      loading: false
    },
    rowProjectsOption: {
      projects: [],
      page: 1,
      hasNext: true,
      projectFilter: "",
      loading: false
    },
    rowCategoryOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      projectFilter: "",
      loading: false
    },
    rowDialogCategoryOptions: {
      projects: [],
      page: 1,
      hasNext: true,
      projectFilter: "",
      loading: false
    }
  }),

  mixins: [MomentMixin],

  methods: {
    ...mapActions(["setClockInDateAndConfirmed"]),

    addToFilter(value, type) {
      if (type === "date") {
        this.date = value.date;
        this.$router.push({ name: "Reports", query: { date: value.date } });
      } else if (type === "employee") {
        this.employeeId = value;
      } else if (type === "project") {
        this.projectId = value;
      }
      this.parentMenu = false;
    },

    async fetchMoreEmployees() {
      this.employeeQueryVars.page++;

      const res = await this.$apollo.query({
        query: employeesQuery,
        variables: { ...this.employeeQueryVars, name: this.employeeToSearch },
        fetchPolicy: "network-only"
      });

      this.hasNextEmployees = res.data.employees.hasNext;
      this.employeeList.push(...res.data.employees.content);
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchMoreEmployees();
      }
    },

    onProjectIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchProjects("availableProjectOptions");
      }
    },

    changeDate(date) {
      this.date = date;
      this.filters.dateStr = this.date;
      this.$router.push({ name: "Reports", query: { date: date } });
      this.resetScroll("grid-mid-row");
    },

    checkCompletedClockInsForConfirm() {
      const mappedClockIns = this.clockInTableData
        .filter(e => e?.clockIns?.length)
        .map(({ name, clockIns, id }) =>
          clockIns.map(a => ({
            clockIn: {
              ...a?.clockIn
            },
            clockOut: {
              ...a?.clockOut
            },
            employee: name,
            id: id,
            confirmed: true
          }))
        )
        .flat()
        .filter(e => e?.clockIn?.id && !e.clockOut?.id);

      if (mappedClockIns.length > 0) return this.setConfirmWarning(mappedClockIns);
      this.confirmAllClockInDialog = false;
      this.confirmAllClockInDialog = true;
    },

    setConfirmWarning(array) {
      const employeesMissingClockOut = array.map(e => {
        return { name: e.employee, id: e.id };
      });
      this.incompleteClockInEmployees = employeesMissingClockOut;

      this.confirmWarningDialog = true;
    },

    async confirmTodaysClockIns() {
      this.confirmLoading = true;
      try {
        await this.$apollo
          .mutate({
            mutation: confirmClockInsForOneDateMutation,
            variables: {
              dateStr: this.date
            }
          })
          .catch(error => {
            this.handleError(error);
          });
        this.$apollo.queries.clockIns.refetch();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
      this.confirmLoading = false;
      this.confirmWarningDialog = false;
      this.confirmAllClockInDialog = false;
    },

    async setConfirmedStatus(itemsArray) {
      const hasNonConfirmed = e => e.confirmed === false;

      const mappedClockIns = this.mapClockInsArray(itemsArray);

      let allConfirmed = false;

      if (mappedClockIns.length > 0) allConfirmed = !mappedClockIns.some(hasNonConfirmed);

      const payload = {
        date: this.date,
        confirmed: allConfirmed
      };

      await this.setClockInDateAndConfirmed(payload);
    },

    mapClockInsArray(array) {
      const mappedClockIns = array
        .map(e => e.clockIns)
        .filter(e => e?.length)
        .flat()
        .filter(e => e?.clockIn?.id);

      return mappedClockIns;
    },

    saveNewClockInPair(employeeName, clockInPair) {
      if (clockInPair.referenceDate == this.date) {
        this.clockInTableData.find(citd => citd.name == employeeName).clockIns.push(clockInPair);
        this.checkConfirmedClockIns();
      }
    },

    editClockInPair(employeeName, clockInPair) {
      const clockInTableRow = this.clockInTableData.find(citd => citd.name == employeeName);
      const index = clockInTableRow.clockIns.indexOf(clockInTableRow.clockIns.find(ci => ci.clockIn.id == clockInPair.clockIn.id));
      if (index == -1) {
        this.saveNewClockInPair(employeeName, clockInPair);
      } else if (clockInPair.referenceDate == this.date) {
        clockInTableRow.clockIns[index] = clockInPair;
      } else {
        clockInTableRow.clockIns.splice(index, 1);
      }
      this.checkConfirmedClockIns();
    },

    deleteClockInPair(employeeName, clockInPair) {
      const clockInTableRow = this.clockInTableData.find(citd => citd.name == employeeName);
      const index = clockInTableRow.clockIns.indexOf(clockInTableRow.clockIns.find(ci => ci.clockIn.id == clockInPair.clockIn.id));
      clockInTableRow.clockIns.splice(index, 1);
      this.checkConfirmedClockIns();
    },

    checkConfirmedClockIns() {
      let allClockInsAreConfirmed = true;

      this.clockInTableData.forEach(citd => {
        if (citd.clockIns.some(ci => ci.id > 0 && !ci.confirmed)) allClockInsAreConfirmed = false;

        if (citd.clockIns.some(ci => ci.id > 0) && citd.clockIns.every(ci => ci.id < 0 || ci.confirmed)) {
          citd.confirmed = true;
        } else citd.confirmed = false;
      });

      this.allClockInsAreConfirmed = allClockInsAreConfirmed;
      this.resetScroll("grid-mid-row");
    },

    projectFilterHandler: debounce(function(payload) {
      const { value, property } = payload;

      if (payload?.resetProjectId) {
        if (!value) this.filters.projprojectFilterHandlerectId = null;
      }

      this[property].projects = [];
      this[property].page = 1;
      this[property].hasNext = true;
      this[property].projectFilter = value;

      this.fetchProjects(property);
    }, 850),

    syncScroll(scrollElClass) {
      const elBeingScrolled = document.getElementsByClassName(scrollElClass)[0];
      //virtual row elements
      const virtualRowEls = document.getElementsByClassName("clockin-mid-grid-row");

      for (let i = 0; i <= virtualRowEls.length; i++) {
        if (virtualRowEls[i]) virtualRowEls[i].scrollLeft = elBeingScrolled.scrollLeft;
      }
    },

    resetScroll(scrollElClass) {
      const elBeingScrolled = document.getElementsByClassName(scrollElClass)[0];
      elBeingScrolled.scrollLeft = 0;
      //virtual row elements
      const virtualRowEls = document.getElementsByClassName("clockin-mid-grid-row");

      for (let i = 0; i <= virtualRowEls.length; i++) {
        if (virtualRowEls[i]) virtualRowEls[i].scrollLeft = 0;
      }
    },

    async fetchProjectOptions() {
      try {
        const variables = {
          filter: "",
          fetchNonCategory: true,
          fetchCategory: false,
          activeStatus: true,
          pageSize: 10,
          page: 1
        };

        const res = await this.$apollo.query({
          query: projectsQuery,
          variables: variables,
          fetchPolicy: "network-only"
        });

        const { content, hasNext } = res.data.projects;

        const projectListItems = ["availableProjectOptions", "rowDialogProjectOptions", "rowProjectsOption"];

        for (const projectListItem of projectListItems) {
          this[projectListItem].projects.push(...content);
          this[projectListItem].hasNext = hasNext;
          this[projectListItem].page++;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async fetchProjects(property) {
      this[property].loading = true;
      try {
        const { page, projectFilter } = this[property];

        const variables = {
          filter: projectFilter,
          fetchNonCategory: true,
          fetchCategory: false,
          activeStatus: true,
          pageSize: 10,
          page: page
        };

        const res = await this.$apollo.query({
          query: projectsQuery,
          variables: variables,
          fetchPolicy: "network-only"
        });

        const { content, hasNext } = res.data.projects;

        this[property].projects.push(...content);
        this[property].hasNext = hasNext;
        this[property].page++;
      } catch (e) {
        console.log(e);
      }
      this[property].loading = false;
    },

    projectCategoryFilterHandler: debounce(function(payload) {
      const { value, property } = payload;

      this[property].projects = [];
      this[property].page = 1;
      this[property].hasNext = true;
      this[property].projectFilter = value;

      this.fetchCategories(property);
    }, 850),

    async fetchCategoryProjectOptions() {
      try {
        const variables = {
          filter: "",
          fetchNonCategory: false,
          fetchCategory: true,
          activeStatus: true,
          pageSize: 10,
          page: 1
        };

        const res = await this.$apollo.query({
          query: categoryProjectsQuery,
          variables: variables,
          fetchPolicy: "network-only"
        });

        const { content, hasNext } = res.data.categoryProjects;

        const projectListItems = ["rowDialogCategoryOptions", "rowCategoryOptions"];

        for (const projectListItem of projectListItems) {
          this[projectListItem].projects.push(...content);
          this[projectListItem].hasNext = hasNext;
          this[projectListItem].page++;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async fetchCategories(property) {
      this[property].loading = true;
      try {
        const { page, projectFilter } = this[property];

        const variables = {
          filter: projectFilter,
          fetchNonCategory: false,
          fetchCategory: true,
          activeStatus: true,
          pageSize: 10,
          page: page
        };

        const res = await this.$apollo.query({
          query: categoryProjectsQuery,
          variables: variables,
          fetchPolicy: "network-only"
        });

        const { content, hasNext } = res.data.categoryProjects;

        this[property].projects.push(...content);
        this[property].hasNext = hasNext;
        this[property].page++;
      } catch (e) {
        console.log(e);
      }
      this[property].loading = false;
    },

    selectProjectHandler(project) {
      this.filters.projectId = project.id;
      this.projectIdThrowAway = project.keyCode;
      // this.availableProjectOptions.projectFilter = project.keyCode;
      this.availableProjectOptions.projectFilter = "";
      this.projectFilterHandler({ value: "", property: "availableProjectOptions", resetProjectId: false });
    }
  },

  computed: {
    ...mapState({
      clockInsDate: state => state.clockIns.clockInsDate,
      confirmedClockIns: state => state.clockIns.confirmedClockIns
    }),

    icons: () => ({
      mdiPlus,
      mdiCalendar,
      mdiChevronDown,
      mdiAlert
    }),
    translations: () => translations,

    teamTypeFilterItems: () => {
      return getTeamTypes().map(type => {
        return { text: translations[type.label], value: type.value };
      });
    },

    confirmedFilterItems: () => [
      { text: translations.confirmedAndNot, value: null },
      { text: translations.confirmed, value: true },
      { text: translations.notConfirmed, value: false }
    ],

    exportReports() {
      return getExportReports();
    },

    middleContainerTemplateColumns() {
      let columns = [
        "110px" /* project */,
        "110px" /* part */,
        "80px" /* sub part */,
        "80px" /* allowance */,
        "120px" /* hour in */,
        "120px" /* hour out */,
        "60px" /* total hours */
      ];

      if (this.possibleQuestions.includes(4)) columns.push("100px" /* lunch break */);
      columns.push("80px" /* layoff */);

      if (this.possibleQuestions.includes(1)) columns.push("80px" /* van */);
      if (this.possibleQuestions.includes(2)) columns.push("90px" /* refuel */);

      columns.push("90px"); /* maintenance */

      if (this.possibleQuestions.includes(3)) columns.push("80px" /* milling */);
      if (this.possibleQuestions.includes(20)) columns.push("80px" /* preparation */);
      if (this.possibleQuestions.includes(5)) columns.push("90px" /* dpiVerification */);
      if (this.possibleQuestions.includes(6)) columns.push("90px" /* materialUsed */);
      if (this.possibleQuestions.includes(7)) columns.push("90px" /* asphaltTemperature */);
      if (this.possibleQuestions.includes(8)) columns.push("90px" /* environmentTemperature */);
      if (this.possibleQuestions.includes(9)) columns.push("90px" /* manHolesVerification */);

      columns.push("80px"); /* vehicle */

      if (this.possibleQuestions.includes(11)) columns.push("90px" /* security */);

      columns.push("130px"); /* note */
      columns.push("100px"); /*reference date */

      return columns.toString().replace(/,/g, " ");
    },

    middleColumnWidth() {
      let width = window.innerWidth;
      // Remove
      //56 for side menu
      //remove 13 for padding
      //remove 265 for name and confirm
      //remove 100 for edit buttons and reference turn
      //remove 60 for actions
      width = width - 494;

      //represent all of the columns inside middle column
      const midColumns = this.middleContainerTemplateColumns
        .split(" ")
        .map(str => Number(str.replace("px", "")))
        .reduce((sum, int) => sum + int, 0);

      width = width > midColumns ? midColumns : width;

      return `${width}px`;
    }
  }
};
</script>

<style>
.f-12 {
  font-size: 12px;
}

.label-style {
  font-size: 13px;
}

.time-report .v-text-field.v-text-field--solo .v-input__control {
  min-height: 10px;
}

.time-report .v-label {
  font-size: 10px;
}

.time-report .v-textarea textarea {
  line-height: 18px! Important;
}

.time-report .v-textarea textarea[readonly="readonly"] {
  color: #a6a6a6;
}
.time-report:not(.v-select).v-text-field input[readonly="readonly"] {
  color: #a6a6a6;
}

.filters-container {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-column-gap: 10px;
  position: absolute;
  left: 30px;
  top: 2px;
}

.grid-row {
  padding-left: 0 !important;
  align-items: center;
  display: grid;
}

.grid-mid-row {
  padding-left: 0 !important;
  align-items: center;
  overflow-x: scroll;
  display: grid;
}

.grid-row > * {
  text-align: center;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  z-index: 1;
}

.time-report .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.table-header {
  font-size: 12px;
  color: #767676;
  padding-left: 3px;
  padding-bottom: 5px;
}

.apolloLoading {
  display: flex;
  height: 85vh;
  justify-content: center;
  align-items: center;
}

.hover {
  cursor: pointer;
}

.horizontal-scroll-bar::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}

/* Track */
.horizontal-scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.horizontal-scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.horizontal-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.horizontal-scroll-bar {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.all-clockins-confirmed {
  width: 80px;
  height: 36px;
  margin: 0;
}

.all-clockins-confirmed .v-icon {
  margin-left: 12px;
  margin-top: -9px;
}
</style>
